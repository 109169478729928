

.page-pagination-sec{
  margin-bottom: 30px;
  @include breakpoint($min768) {
    margin-bottom: 60px;
  }
}

.page-item:last-child .page-link{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item.active .page-link {

  color: #000;
  background-color: transparent;
  border-color: transparent;
}
.page-link {

  color: #838487;
  background-color: #fff;
  border: 1px solid transparent;
}
.page-item.disabled .page-link {
  color: #a7a8ab;
  border-color: transparent;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: $yellow_c;
  border-color: transparent;
}