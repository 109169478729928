.parsley-errors-list {
  list-style: none;
  margin: 0;
  padding: 0;
  &.filled{
    color: $red_c;
  }
}

.form-control{
  &.parsley-error{
    border-color: $red_c;
  }

  &.parsley-success{
    border-color: $green_c;
  }
}





/************************************************/
/******************CONTACT FORM******************/
/************************************************/
.field-input {
  margin-bottom: 15px;
}
.field-input input {
  border: none;
  width: 100%;
  height: 35px;
  outline: none;
  border-bottom: 1px solid #838487;
  transition: border-color 0.3s;
}
.field-input textarea {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 10px 20px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #838487;
  transition: border-color 0.3s;
}
.field-input input:focus,
.field-input textarea:focus {
  background-color: transparent;
  border-color: #d0a046;
  transition: border-color 0.3s;
}
/************************************************/
/****************ICON LINE TEXT******************/
/************************************************/
.iconline {
  position: relative;
  color: #838487;
  padding: 8px 0 8px 35px;
  margin-bottom: 5px;
}
.iconline .c-icon {
  position: absolute;
  left: 0;
  top: 9px;
  font-size: 22px;
  color: $yellow_c;
}
.iconline .c-icon:before {
  margin: 0;
}
//.iconline .icon-smartphone {
//  left: -4px;
//  top: -2px;
//  font-size: 30px;
//}
//.iconline .icon-placeholder {
//  font-size: 24px;
//}