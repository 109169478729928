/************************************************/
/*****************SEARCH POPUP*******************/
/************************************************/
.search-popup-window {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.search-popup-window .back-cover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
}

.search-popup-window.show {
  display: block;
}

.search-popup-window-inner {
  height: 100%;
  padding: 0 30px;
}

.search-popup-window .close-win-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 1;
}

.search-popup-window .search-form {
  position: relative;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.search-popup-window .search-form:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: $yellow_c;
  transform: translateX(-100%);
  transition: all 0.8s linear;
}

.search-popup-window.show .search-form:after {
  transform: translateX(0);
  transition: all 0.8s linear;
}

.search-popup-window .search-form input[name="s"] {
  width: 100%;
  border: none;
  height: 50px;
  font-size: 24px;
  font-weight: 300;
  background-color: transparent;
  color: $yellow_c;
  outline: none !important;
}

.search-popup-window .search-form input[name="s"]::-webkit-input-placeholder {
  color: $yellow_c;
}

.search-popup-window .search-form input[name="s"]::-moz-placeholder {
  color: $yellow_c;
}

.search-popup-window .search-form input[name="s"]:-ms-input-placeholder {
  color: $yellow_c;
}

.search-popup-window .searchbutn {
  position: absolute;
  right: 0;
  top: 9px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  color: $yellow_c;
  cursor: pointer;
  outline: none !important;
  transform: translateY(-15px);
  transition: all 0.8s linear;
}

.search-popup-window.show .searchbutn {
  transform: translateY(0);
  transition: all 0.8s linear;
}