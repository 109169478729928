@charset "UTF-8";
/**************************************************/
/****************RESSET STYLE***********************/
/**************************************************/
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center,
cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4,
h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre,
q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr,
tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

/*************************************************************************/
/*****************FIX BOOTSTRAP DEFAULT CONTACINER***********************/
/*************************************************************************/
.container {
  max-width: 1300px;
}

.container-small {
  max-width: 1110px;
}

/***
====================================================================
	Fonts
====================================================================
 ***/
/***
====================================================================
	Colors
====================================================================
 ***/
/***
====================================================================
	transition
====================================================================
 ***/
/***
====================================================================
	clearfix
====================================================================
 ***/
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
.spacer10 {
  height: 10px;
}

.spacer20 {
  height: 20px;
}

.spacer30 {
  height: 30px;
}

.spacer40 {
  height: 40px;
}

.spacer50 {
  height: 50px;
}

.spacer60 {
  height: 60px;
}

.spacer70 {
  height: 70px;
}

.spacer80 {
  height: 80px;
}

.spacer90 {
  height: 90px;
}

.spacer100 {
  height: 100px;
}

/*
.spacer50 --> height: 50px;
 */
/***
====================================================================
	media queries
====================================================================
 ***/
/*
media query example

@include breakpoint($min768) {
		position: fixed;
	}
*/
/***
====================================================================
	Mixins for Rem Font Sizing
====================================================================
 ***/
/*
@include font-size(16); -- 16px 1.6rem
*/
/***
====================================================================
    transition
====================================================================
 ***/
/***
====================================================================
    Shadows
====================================================================
 ***/
/***
====================================================================
	Bootstrap them overrides
====================================================================
 ***/
.form-control {
  border-radius: 2px;
}

::selection {
  background: #FFB100;
  color: #ffffff;
}

::-moz-selection {
  background: #FFB100;
  color: #ffffff;
}

html {
  min-height: 100%;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: #2c3644;
  position: relative;
  font-style: normal;
  background-color: #fff;
}
body img {
  max-width: 100%;
  display: block;
}

.hidden {
  display: none;
}

a, a:hover, a:active, a:focus {
  text-decoration: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul ul, ol ol, ul ol, ol ul {
  padding-left: 25px;
}

input {
  outline: 0;
  box-shadow: none;
  border-radius: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Source Sans Pro", sans-serif;
}

#map {
  height: 400px;
  width: 100%;
}

.req-symbol {
  font-weight: 500;
  color: #F51554;
  font-family: sans-serif;
}

/************************************************/
/***************SCROLL UP BUTTON****************/
/************************************************/
.sctollup.btn_frl {
  cursor: pointer;
  display: none;
  position: fixed;
  right: 50px;
  padding: 0;
  bottom: 50px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 2.9;
  text-align: center;
  background-color: #FFB100;
  color: #fff;
  z-index: 900;
}

.sctollup span {
  opacity: 1;
  color: #fff;
  width: auto;
  top: 0;
  display: inline;
}

.required-mark {
  color: #F51554;
  font-weight: 700;
}

/* Animations Linear*/
.scrollanimation {
  opacity: 0;
}

.animated {
  opacity: 1;
}

/*.fadeInUp{
	animation-duration:.6s;
	animation-timing-function: linear;
}
*/
/*cubic-bezier*/
/*scale*/
@keyframes scale {
  0%, 100% {
    transform: scale(0);
    -moz-transform: scale(0);
  }
  7%, 90% {
    transform: scale(0.4);
    -moz-transform: scale(0.4);
  }
  50% {
    transform: scale(1);
    -moz-transform: scale(1);
  }
}
/*popup Image ZoomIN effect*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**************************************************/
/*****************TYPOGRAPHY***********************/
/**************************************************/
h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 15px;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

a {
  color: #000;
  transition: color 0.2s;
}

a:hover,
a:focus {
  color: #FFB100;
  transition: color 0.2s;
  text-decoration: none;
  outline: none;
}

/************************************************/
/***************DEFAULT CLASSES******************/
/************************************************/
.bodypadding {
  padding: 0 15px;
}
@media (min-width: 992px) {
  .bodypadding {
    padding: 0 50px;
  }
}
.padding100_0 {
  padding: 100px 0;
}

.padding150_0 {
  padding: 150px 0;
}

.padding75_0 {
  padding: 75px 0;
}

.padding_top_250 {
  padding-top: 250px;
}

.padding_top_150 {
  padding-top: 150px;
}

.padding_top_130 {
  padding-top: 130px;
}

.padding_top_100 {
  padding-top: 100px;
}

.padding_btn_80 {
  padding-bottom: 80px;
}

.padding_btn_75 {
  padding-bottom: 75px;
}

.padding_btn_150 {
  padding-bottom: 150px;
}

.padding_btn_30 {
  padding-bottom: 30px;
}

.margin_off {
  margine: 0;
}

.lighbg {
  background-color: #f9f7f7;
}

.lightext {
  color: #838487;
}

.margin_btn_50 {
  margin-bottom: 50px;
}

.slick-slide {
  outline: none !important;
}

/*****Bars Lines******/
/*Nuv Button Toggle*/
.navbar-toggler.show .burslines_menu .line:first-child,
.close_sub_menu .line:first-child {
  transform: translateY(5px) rotate(45deg);
}

.navbar-toggler.show .burslines_menu .line:nth-child(2),
.close_sub_menu .line:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.show .burslines_menu .line:last-child,
.close_sub_menu .line:last-child {
  margin-bottom: 0;
  transform: translateY(-7px) rotate(-45deg);
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler {
  outline: none !important;
}

/*fgbobles*/
.fgbobleslink {
  position: relative;
  text-align: center;
  background-color: transparent;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.fgbobles {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -17px;
  margin-left: -21px;
}

.fgbobles .boble {
  opacity: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 1px;
  border-radius: 50%;
  background-color: #FFB100;
}

.fgbobles .boble:first-child {
  transform: translateX(-40px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.fgbobles .boble:last-child {
  transform: translateX(40px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.fgbobles .boble:nth-child(2) {
  transform: translateY(-40px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.fgbobleslink:hover .fgbobles .boble {
  opacity: 1;
  transform: translateX(0);
  transform: translateY(0);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.fgbobleslink:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

/***
====================================================================
	Elements
====================================================================
 ***/
/************************************************/
/*****************BUTTONS CLASS******************/
/************************************************/
.btn_frl {
  position: relative;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  padding: 12px 25px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
}

.btn_frl_big {
  padding: 18px 45px;
}

.btn_frl.btn_trnsp {
  background-color: transparent;
  border: 1px solid #000;
}

.btn_frl.btn_gold {
  background-color: #FFB100;
  color: #fff;
}

.btn_frl.btn-white {
  background-color: #fff;
  color: #333;
}
.btn_frl.btn-white:after {
  box-shadow: 0 0 0 1px #fff;
}

.btn_frl.btn-black {
  background-color: #222;
  color: #fff;
}
.btn_frl.btn-black:after {
  box-shadow: 0 0 0 1px #222;
}

.btn_frl:after {
  opacity: 0;
  position: absolute;
  content: "";
  left: -4px;
  right: -4px;
  bottom: -4px;
  top: -4px;
  box-shadow: 0 0 0 1px #FFB100;
  transform: scale(0.5, 0.5);
  transition: transform 0.4s, opacity 0.4s;
}

.btn_frl i {
  opacity: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  max-width: 0;
  font-size: 13px;
  width: 1.8em;
  vertical-align: middle;
  text-align: right;
  text-indent: 0;
  line-height: 1;
  transition: opacity 0.3s, max-width 0.3s;
}

.btn_frl.btn_gold i {
  color: #fff;
}

.btn_frl:hover,
.btn_frl:focus {
  border: none;
  outline: none;
}

.btn_frl:hover:after {
  transform: scale(1, 1);
  opacity: 1;
  transition: transform 0.2s, opacity 0.2s;
}

.btn_frl.btn_trnsp:hover {
  background-color: #FFB100;
  color: #fff;
  border: 1px solid #FFB100;
}

.btn_frl:hover i {
  opacity: 1;
  max-width: 3em;
  will-change: opacity, max-width;
  transition: opacity 0.3s, max-width 0.3s;
}

.btn_frl_sml {
  position: relative;
  display: inline-block;
  padding: 1px 5px;
  margin-bottom: 4px;
  background-color: transparent;
  outline: none;
  font-size: 12px;
  font-family: "Source Sans Pro", sans-serif;
  color: #FFB100;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border: 1px solid #FFB100;
}

.btn_frl_sml {
  color: #000;
  border: 1px solid #000;
}

.btn_frl_sml:after {
  position: absolute;
  content: "";
  left: -4px;
  top: -4px;
  bottom: -4px;
  right: -4px;
  box-shadow: 0 0 0 1px #FFB100;
  transform: scale(0.4, 0.4);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.btn_frl_sml:after {
  box-shadow: 0 0 0 1px #000;
}

.btn_frl_sml:hover {
  background-color: #FFB100;
  color: #fff;
  outline: none;
  border-color: #FFB100;
  transition: background-color 0.6s;
}

.btn_frl_sml:hover:after {
  transform: scale(1, 1);
  opacity: 1;
  box-shadow: 0 0 0 1px #FFB100;
  transition: transform 0.3s, opacity 0.3s;
}

/*Buton Click Effect*/
.btn_frl_flt {
  position: relative;
  font-size: 12px;
  line-height: 1;
  outline: none;
  padding: 0 1px;
  background-color: transparent;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: color 0.3s;
}

.btn_frl_flt.active {
  color: #FFB100;
}

.btn_frl_flt:after {
  position: absolute;
  content: "";
  left: -3px;
  top: -3px;
  bottom: -3px;
  right: -3px;
  box-shadow: 0 0 0 1px #FFB100;
  opacity: 0;
  transform: scale(0.4, 0.4);
}

.btn_frl_flt.active:after {
  opacity: 1;
  transform: scale(1, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn_frl_flt:focus,
.btn_frl_flt:hover {
  outline: none;
  color: #FFB100;
  transition: color 0.3s;
}

.parsley-errors-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  color: #F51554;
}

.form-control.parsley-error {
  border-color: #F51554;
}
.form-control.parsley-success {
  border-color: #159548;
}

/************************************************/
/******************CONTACT FORM******************/
/************************************************/
.field-input {
  margin-bottom: 15px;
}

.field-input input {
  border: none;
  width: 100%;
  height: 35px;
  outline: none;
  border-bottom: 1px solid #838487;
  transition: border-color 0.3s;
}

.field-input textarea {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 10px 20px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #838487;
  transition: border-color 0.3s;
}

.field-input input:focus,
.field-input textarea:focus {
  background-color: transparent;
  border-color: #d0a046;
  transition: border-color 0.3s;
}

/************************************************/
/****************ICON LINE TEXT******************/
/************************************************/
.iconline {
  position: relative;
  color: #838487;
  padding: 8px 0 8px 35px;
  margin-bottom: 5px;
}

.iconline .c-icon {
  position: absolute;
  left: 0;
  top: 9px;
  font-size: 22px;
  color: #FFB100;
}

.iconline .c-icon:before {
  margin: 0;
}

.sec-header {
  margin-bottom: 24px;
}

.title-logo {
  color: #FFB100;
  line-height: 1;
}
.title-logo span {
  font-size: 30px;
  position: relative;
  bottom: -1px;
}

.sec-title__logo {
  display: inline-block;
  border-top: 1px solid #FFB100;
  padding: 0 10px;
  padding-top: 8px;
}

.sec-title__1 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}
@media (min-width: 992px) {
  .sec-title__1 {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .sec-title__1 {
    font-size: 32px;
  }
}

.sec-title__2 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 400;
}

.sec-title__3 {
  margin-bottom: 0;
  font-size: 20px;
}

.sec-title__4 {
  margin-bottom: 0;
  font-size: 18px;
}

.sec-title__no-logo {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}
@media (min-width: 992px) {
  .sec-title__no-logo {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .sec-title__no-logo {
    font-size: 30px;
  }
}

.sec-image img {
  border: 5px solid #fff;
  display: inline-block;
  box-shadow: 0 7px 30px rgba(0, 0, 0, 0.1);
}

.full-width-sec {
  margin-left: -15px;
  margin-right: -15px;
}
@media (min-width: 992px) {
  .full-width-sec {
    margin-left: -50px;
    margin-right: -50px;
  }
}
/************************************************/
/****************SERVICES SECTION****************/
/************************************************/
.services-sec {
  padding-top: 100px;
  padding-bottom: 60px;
}
@media (max-width: 991px) {
  .services-sec {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

.services-sec__body {
  background-image: url("../img/service-bg-2-40.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 80px;
  margin-top: -80px;
}
@media (min-width: 576px) {
  .services-sec__body {
    padding-top: 110px;
    margin-top: -110px;
  }
}
@media (min-width: 768px) {
  .services-sec__body {
    padding-top: 160px;
    margin-top: -160px;
  }
}
@media (min-width: 992px) {
  .services-sec__body {
    padding-top: 200px;
    margin-top: -200px;
  }
}
@media (min-width: 1200px) {
  .services-sec__body {
    padding-top: 260px;
    margin-top: -260px;
  }
}
@media (min-width: 1600px) {
  .services-sec__body {
    padding-top: 300px;
    margin-top: -300px;
  }
}

.services-sec__inner {
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .services-sec__inner {
    padding-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.services-sec__card {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5px;
}

.services-sec__card-title {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.services-sec__card-title a {
  color: #000;
  display: block;
}
.services-sec__card-title a:hover {
  color: #FFB100;
  text-decoration: underline;
}

.services-sec__card-text {
  color: #838487;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

/************************************************/
/****************NEWS SECTION****************/
/************************************************/
.news-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

/************************************************/
/**************PREVIEW POST CARD*****************/
/************************************************/
.prev-post__image-link {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 0;
  overflow: hidden;
}

.prev-post__image-link img,
.prev-post__image-link a {
  display: block;
}

.prev-post__image-link img {
  width: 100%;
  transform: scale(1.06);
  transition: transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
}

.prev-post__image-link:hover img {
  transform: scale(1.06) translateX(3%);
  transition: transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
}

.prev-pots__label {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 3;
}
.prev-pots__label .prev-post__data {
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  color: #FFB100;
  padding: 4px 15px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: #fff;
}

.prev-post__text,
.prev-post__link {
  text-align: center;
}

.prev-post__text {
  margin: 25px 0 0 0;
}

.prev-post__title {
  height: 46px;
  overflow: hidden;
  margin-bottom: 0px;
}
.prev-post__title a:hover {
  color: #FFB100;
  text-decoration: underline;
}

.prev-post__short {
  color: #838487;
  margin-bottom: 18px;
  font-size: 14px;
  height: 40px;
  overflow: hidden;
}

.prev-post__card.small-card {
  background-color: #fafafa;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
  padding-bottom: 15px;
}

.prev-post__row-title {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .prev-post__row-card {
    display: flex;
  }
}
.prev-post__row-card .prev-post__image-link {
  flex: 1 0 25%;
  margin-right: 30px;
}
.prev-post__row-card .prev-post__text {
  text-align: left;
}

.call-to-action {
  background-image: url("../img/call-to-action-2.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .call-to-action {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.cta__title {
  color: #fff;
  font-size: 60px;
  font-weight: 300;
  border-top: 1px solid #fff;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .cta__title {
    font-size: 40px;
  }
}

.cta__title-logo span {
  font-size: 80px;
  color: #fff;
}

/************************************************/
/******************BREADCRUMBS*******************/
/************************************************/
.small-breadcrumbs .breadcrumbs-sec {
  margin-bottom: 20px;
}

.breadcrumbs-sec {
  margin-bottom: 60px;
  padding: 10px 0;
  border-top: 1px solid #efeeed;
  border-bottom: 1px solid #efeeed;
}
@media (max-width: 767px) {
  .breadcrumbs-sec {
    margin-bottom: 20px;
  }
}

.page_list li {
  float: left;
}

.page_list li,
.page_list a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.page_list li .separatelist {
  display: inline-block;
  padding: 0 13px;
}

/************************************************/
/***************POST NAVIGATION******************/
/************************************************/
.post-navigation {
  position: relative;
  text-align: center;
  padding: 23px 0;
  border-top: 1px solid #efeeed;
  border-bottom: 1px solid #efeeed;
}

.post-navigation .prev,
.post-navigation .next {
  position: absolute;
  top: 50%;
  margin-top: -13px;
}

.post-navigation .prev {
  left: 0;
}

.post-navigation .next {
  right: 0;
}

.post-navigation a {
  color: #838487;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1em;
  opacity: 0.6;
  transition: color, opacity, 0.6s;
}

.back-to-parent a {
  font-size: 24px;
}

.post-navigation a:hover {
  opacity: 1;
  color: #FFB100;
  transition: color, opacity, 0.6s;
}

.page-pagination-sec {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .page-pagination-sec {
    margin-bottom: 60px;
  }
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item.active .page-link {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.page-link {
  color: #838487;
  background-color: #fff;
  border: 1px solid transparent;
}

.page-item.disabled .page-link {
  color: #a7a8ab;
  border-color: transparent;
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #FFB100;
  border-color: transparent;
}

/***
====================================================================
	Layouts
====================================================================
 ***/
.services-other-sec {
  background-color: #FAFAFA;
  padding-top: 100px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .services-other-sec {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.services-other-sec .item_post {
  margin-bottom: 30px;
}

.page-main-image {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .page-main-image {
    margin-bottom: 20px;
  }
}
.page-main-image img {
  width: 100%;
}

.news-list-sec {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .news-list-sec {
    margin-bottom: 20px;
  }
}

.error-page-sec {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .error-page-sec {
    margin-bottom: 20px;
  }
}
.error-page-sec a {
  color: #FFB100;
}
.error-page-sec a:hover {
  text-decoration: underline;
}

.error-page__title {
  font-size: 40px;
  line-height: 1;
}

.error-page__content p {
  font-size: 18px;
}

.contact-sec {
  margin-bottom: 30px;
}

.map-sec {
  margin-bottom: 50px;
}

.map-wrap {
  height: 400px;
}

/************************************************/
/******************MOBILE NAV********************/
/************************************************/
.mobile_nav {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 990;
}

.mobile_nav .back_cover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(249, 247, 248, 0.4);
}

.mobile_nav_inner {
  position: absolute;
  left: -300px;
  top: 0;
  width: 300px;
  height: 100%;
  min-height: 100%;
  text-align: left;
  padding: 40px 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 9999;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  box-shadow: 0 3px 1px 0 rgba(187, 187, 187, 0.5);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  overflow-y: scroll;
}

.mobile_nav.show .mobile_nav_inner {
  left: 0;
  visibility: visible;
}

.mobile_nav_inner .close_win_btn {
  position: absolute;
  right: 15px;
  top: 15px;
}

.mobile_nav li {
  position: relative;
  border-bottom: 1px solid #eaeaea;
}

.mobile_nav .sub-menu {
  display: none;
  padding: 0 15px;
}

.mobile_nav li:last-child {
  border-bottom: none;
}

.mobile_nav li a {
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 10px 30px;
}

.mobile_nav .sub_menu_btn {
  position: absolute;
  top: 4px;
  right: 20px;
  width: 30px;
  height: 30px;
  padding-top: 9px;
  box-sizing: border-box;
}

/************************************************/
/*****************SEARCH POPUP*******************/
/************************************************/
.search-popup-window {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.search-popup-window .back-cover {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
}

.search-popup-window.show {
  display: block;
}

.search-popup-window-inner {
  height: 100%;
  padding: 0 30px;
}

.search-popup-window .close-win-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 1;
}

.search-popup-window .search-form {
  position: relative;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.search-popup-window .search-form:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #FFB100;
  transform: translateX(-100%);
  transition: all 0.8s linear;
}

.search-popup-window.show .search-form:after {
  transform: translateX(0);
  transition: all 0.8s linear;
}

.search-popup-window .search-form input[name=s] {
  width: 100%;
  border: none;
  height: 50px;
  font-size: 24px;
  font-weight: 300;
  background-color: transparent;
  color: #FFB100;
  outline: none !important;
}

.search-popup-window .search-form input[name=s]::-webkit-input-placeholder {
  color: #FFB100;
}

.search-popup-window .search-form input[name=s]::-moz-placeholder {
  color: #FFB100;
}

.search-popup-window .search-form input[name=s]:-ms-input-placeholder {
  color: #FFB100;
}

.search-popup-window .searchbutn {
  position: absolute;
  right: 0;
  top: 9px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  color: #FFB100;
  cursor: pointer;
  outline: none !important;
  transform: translateY(-15px);
  transition: all 0.8s linear;
}

.search-popup-window.show .searchbutn {
  transform: translateY(0);
  transition: all 0.8s linear;
}

/************************************************/
/*****************FLOAT SITEBAR******************/
/************************************************/
.float_sidebar_window {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
}

.float_sidebar_window .back_cover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(249, 247, 248, 0.4);
}

.float_sidebar_window_inner {
  position: fixed;
  right: -475px;
  top: 0;
  width: 475px;
  margin-right: -20px;
  height: 100%;
  min-height: 100%;
  text-align: left;
  padding: 5.5% 80px 38px 79px;
  overflow: hidden;
  visibility: hidden;
  z-index: 9999;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  box-shadow: 0 3px 1px 0 rgba(187, 187, 187, 0.5);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  overflow-y: scroll;
}

.float_sidebar_window.show .float_sidebar_window_inner {
  right: 0;
  visibility: visible;
}

.float_sidebar_container {
  font-size: 18px;
  color: #838487;
  text-align: center;
}

.float_sidebar_window .close_win_btn {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.nicescroll-cursors {
  background-color: #fff !important;
}

/************************************************/
/*************FLOAT SITEBAR WIDGETS**************/
/************************************************/
.float_sidebar_container .widget_sidearea {
  margin-bottom: 15px;
}

.float_sidebar_container .widget_text p {
  font-weight: 300;
  line-height: 1.6;
}

.intsgramname {
  color: #FFB100;
  font-style: italic;
}

/************************************************/
/******************MAIN HEADER*******************/
/************************************************/
.main_header_wrapp {
  position: relative;
  height: 125px;
}
@media (max-width: 991px) {
  .main_header_wrapp {
    height: 90px;
  }
}

.logohead,
.main_header_right {
  position: relative;
  z-index: 10;
}

/*Main Head Logo*/
.logohead {
  float: left;
  height: 100%;
}

.logohead a {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 991px) {
  .logohead a {
    width: 160px;
  }
}

.logohead:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
}

/*Main Head Right*/
.main_header_right {
  float: right;
  height: 100%;
}

.main_header_right_inner {
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.main_header_right_inner a {
  text-decoration: none;
  color: #000;
  line-height: 1;
}

/*Lang btn list */
.lang-btn__list {
  margin-right: 20px;
}
.lang-btn__list a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1;
  padding: 0 5px 0 5px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  letter-spacing: 0.2em;
}
.lang-btn__list a:hover {
  color: #FFB100;
}
.lang-btn__list a.active {
  opacity: 0.5;
}
.lang-btn__list a.active:hover {
  color: #000;
  cursor: initial;
}

/*Search*/
.search-btn a {
  font-size: 22px;
}

/*Sidebar Btn*/
.sidebar-btn {
  margin-left: 25px;
}

/*Shoping Cart DropDown*/
.shopping-cart-holder {
  margin: 0 0 0 15px;
}

.shopping-cart-holder,
.shopping-cart-inner {
  height: 100%;
}

.shopping-cart-inner {
  position: relative;
}

.header-cart {
  position: relative;
  font-size: 25px;
  color: #000;
}

.header-cart:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
}

.header-cart .cart-number {
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: center;
  font-size: 11px;
  line-height: 1.3;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #FFB100;
}

.shopping-cart-holder .widget_shopping_cart_content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 90px;
  background-color: #fff;
  width: 280px;
  transform: translateY(-10px);
  transition: all 0.2s linear;
}

.shopping-cart-inner:hover .widget_shopping_cart_content {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s linear;
}

/***********Top Nav**************/
.top_main_nav,
.top_nav_links,
.top_nav_links_wrapp,
ul.navbar-nav {
  height: 100%;
}

.top_main_nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.top_nav_links {
  padding: 0;
}

.top_nav_links ul.navbar-nav {
  margin: auto;
}

.top_nav_links ul.navbar-nav li {
  position: relative;
}

.top_nav_links ul.navbar-nav > li {
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top_nav_links .navbar-nav a {
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1;
  padding: 0 20px 0 20px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  letter-spacing: 0.2em;
}

.top_nav_links .navbar-nav a span {
  position: relative;
  display: inline-block;
}

.top_nav_links .navbar-nav a span:after {
  position: absolute;
  content: "";
  left: -6px;
  right: -4px;
  top: -3px;
  bottom: -3px;
  box-shadow: 0 0 0 1px #FFB100;
  transform: scale(0, 0);
  transition: all 0.2s linear;
}

.top_nav_links .navbar-nav .current-menu-item a span:after,
.top_nav_links .navbar-nav li:hover > a span:after {
  transform: scale(1, 1);
}

.top_nav_links .current-menu-item > a,
.top_nav_links li:hover > a {
  color: #FFB100;
}

.top_nav_links .navbar-nav .sub-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 220px;
  padding: 17px 0;
  background-color: #fff;
  box-sizing: border-box;
  left: 0;
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.05);
  top: 90px;
  transform: translateY(-10px);
  transition: all 0.2s linear;
  z-index: 9;
}

.main_sticky_header .top_nav_links .navbar-nav .sub-menu {
  top: 65px;
}

.top_nav_links .navbar-nav .sub-menu .sub-menu {
  left: 100%;
  top: 0;
  box-shadow: 10px 12px 50px rgba(0, 0, 0, 0.05);
}

.top_nav_links .navbar-nav .sub-menu li {
  float: none;
  padding: 0 25px;
}

.top_nav_links .navbar-nav .sub-menu li a {
  display: block;
  padding: 8px 0;
  border-bottom: 1px solid #b3b3b3;
  opacity: 0.95;
}

.top_nav_links .navbar-nav .sub-menu li:last-child a {
  border-bottom: 0px solid #b3b3b3;
}

.top_nav_links .navbar-nav .sub-menu a span:after {
  display: none;
}

.top_nav_links .menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s linear;
}

/************************************************/
/*****************STICKY HEADER******************/
/************************************************/
.main_sticky_header {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  z-index: 15;
  box-sizing: border-box;
  padding: 0 50px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.6s ease;
  -moz-transition: -moz-transform 0.6s ease;
  transition: transform 0.6s ease;
}
.main_sticky_header .logohead img {
  width: 160px;
}
@media (max-width: 991px) {
  .main_sticky_header {
    padding: 0 15px;
  }
}

.main_sticky_header.header_appear {
  transform: translateY(0);
}

/************************************************/
/*********************FOOTER*********************/
/************************************************/
.main-footer {
  padding-top: 80px;
  background-color: #f9f7f7;
  background-image: url("../img/footer-bg-14.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@media (max-width: 767px) {
  .main-footer {
    padding-top: 40px;
  }
}

.page_home .main-footer {
  padding-top: 250px;
  margin-top: -250px;
}
@media (max-width: 767px) {
  .page_home .main-footer {
    padding-top: 300px;
    margin-top: -300px;
  }
}

.footer-bottom {
  padding: 45px 0;
  margin-top: 30px;
  border-top: 1px solid #646262;
}

.footer-bottom .copyright {
  float: left;
  font-size: 14px;
  color: #333;
}

.footer-bottom .socilalist_footer {
  float: right;
}

.footer-bottom .socilalist_footer a {
  font-weight: 300;
  color: #838487;
}

.footer-logo img {
  max-width: 200px;
}

.footer-logo__text {
  color: #333;
}
.footer-logo__text b {
  font-weight: 700;
}

.signature {
  color: #333;
}
.signature a {
  color: #333;
  background-image: url(../img/apaone-logo-rgay.png);
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: 0;
  text-decoration: none;
}

.contact-list li {
  position: relative;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 8px 0 8px 35px;
  color: #333;
}
.contact-list li a {
  color: #333;
}
.contact-list li a:hover {
  color: #F51554;
  text-decoration: underline;
}

.contact-list li span.f-icon {
  position: absolute;
  left: 0;
  font-size: 22px;
  color: #F51554;
}

.footer-col .widget_text {
  font-size: 14px;
  color: #838487;
}
.footer-col .widget-recent-post .post-name a:hover,
.footer-col .widget-recent-post .post-data {
  color: #F51554;
}

.widget-footer__title {
  color: #333;
}

.footer-soc-link {
  display: inline-block;
  padding: 10px;
  font-size: 18px;
}

/************************************************/
/**************SLICK SLIDER STYLES***************/
/************************************************/
.farrellslick_nav_wrapp {
  text-align: center;
  padding-top: 30px;
}

.farrellslick_nav_inner {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 0 35px;
}

.farrellslick_nav_inner .slick-arrow {
  position: absolute;
  top: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
}

.farrellslick_nav_inner .slick-prev {
  left: 0;
}

.farrellslick_nav_inner .slick-next {
  right: 0;
}

.farrellslick_nav_inner .slick-dots {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.farrellslick_nav_inner .slick-dots li {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.farrellslick_nav_inner .slick-dots li {
  line-height: 0;
}

.farrellslick_nav_inner .slick-dots li button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  color: transparent !important;
  font-size: 12px;
  width: 30px;
  height: 1px;
  background-color: #efeeed;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.farrellslick_nav_inner .slick-dots li.slick-active button {
  background-color: #000;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

/************************************************/
/*************SERVICES CARD SLIDER***************/
/************************************************/
.services_cards_slides .slick-slide {
  padding-left: 30px;
}

.services_cards_slides .slick-list {
  margin-left: -30px;
}

.services_cards_slides .item_inner {
  outline: none !important;
}

.services_cards_slides .item {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.services_cards_slides .item:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgba(13, 37, 66, 0) 57%, #000000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgba(13, 37, 66, 0) 57%, #000000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(13, 37, 66, 0) 57%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#001e5799", endColorstr="#000000", GradientType=0);
  /* IE6-9 */
}

.services_cards_slides .item > img {
  width: 100%;
}

.item_link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  outline: none;
}

.services_cards_slides .carddesc {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  z-index: 2;
}

.services_cards_slides .namecard {
  font-weight: 700;
  color: #fff;
  font-size: 40px;
  margin-bottom: 5px;
}

.services_cards_slides .shortcarddesc {
  font-size: 14px;
  max-width: 300px;
  margin: auto;
}

/*Demo Background*/
.sishingbg {
  background-image: url(../images/cards1.jpg);
}

.kayakingbg {
  background-image: url(../images/cards2.jpg);
}

.shopgbg {
  background-image: url(../images/cards3.jpg);
}

.navslider {
  margin-top: 35px;
}

.navslider_inner {
  position: relative;
  padding: 0 30px;
  max-width: 340px;
  margin: auto;
  height: 22px;
}

.navslider_inner .slick-list {
  top: 7px;
}

.navslider_inner .item {
  display: inline-block;
  background-color: transparent;
  border: none;
  padding: 2px 0;
  font-size: 10px;
  line-height: 1.3;
  color: transparent;
  outline: none;
  cursor: pointer;
  width: 75px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.navslider_inner .slick-current {
  border-bottom: 1px solid #000;
}

.navslider_inner .slick-arrow {
  position: absolute;
  top: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.navslider_inner .slick-prev {
  left: 0;
}

.navslider_inner .slick-next {
  right: 0;
}

.sidebar-widget {
  margin-bottom: 50px;
}

/************************************************/
/****************NAVIGATION WIDGET***************/
/************************************************/
.widget-nav-menu li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 15px;
}

.widget-nav-menu li:before {
  position: absolute;
  content: "•";
  left: 0;
  top: 0;
  color: #F51554;
}

.widget-nav-menu a {
  font-size: 14px;
  color: #333;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.widget-nav-menu a:hover {
  color: #F51554;
}

/************************************************/
/*******************RECENT POST******************/
/************************************************/
.widget-recent-post .post-data {
  font-size: 12px;
  font-weight: 600;
  color: #FFB100;
  text-transform: uppercase;
}

.widget-recent-post .post-name a {
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #333;
  text-transform: uppercase;
}

.widget-recent-post .post-name a:hover {
  color: #FFB100;
}

/************************************************/
/*******************SERVICES LIST******************/
/************************************************/
.widget-services-list li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 15px;
}
.widget-services-list li:before {
  position: absolute;
  content: "•";
  left: 0;
  top: 0;
  color: #FFB100;
}
.widget-services-list a {
  font-size: 14px;
  color: #333;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.widget-services-list a:hover {
  color: #FFB100;
}

/************************************************/
/*****************PAGE HEADER********************/
/************************************************/
.page_header {
  position: relative;
  min-height: 305px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fff;
  margin-bottom: 60px;
}

.page_header .sec-title__1 {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}
@media (min-width: 992px) {
  .page_header .sec-title__1 {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .page_header .sec-title__1 {
    font-size: 50px;
  }
}
.page_header .title-logo span {
  font-size: 30px;
}
@media (min-width: 768px) {
  .page_header .title-logo span {
    font-size: 50px;
  }
}

.page_header.no-image .sec-title__1 {
  color: #2c3644;
}

.cover_color {
  position: relative;
}

.cover_color:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
  z-index: -1;
}

.page_header_wrapper {
  position: relative;
  z-index: 2;
}

.parallaximg {
  display: none;
}

.gallery-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.gallery-item {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
}

.gallery-image__link {
  display: block;
  position: relative;
  overflow: hidden;
}
.gallery-image__link img {
  display: block;
  width: 100%;
}
.gallery-image__link:before {
  opacity: 0;
  position: absolute;
  content: "";
  font-family: "icomoon" !important;
  font-size: 22px;
  left: 50%;
  top: 50%;
  margin-left: -11px;
  margin-top: -15px;
  color: #d0a046;
  transform: translateY(-50px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 2;
}
.gallery-image__link:after {
  opacity: 0;
  position: absolute;
  content: "";
  left: -20px;
  top: -20px;
  bottom: -20px;
  right: -20px;
  border: 1px solid #f9f7f7;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 1;
}
.gallery-image__link:hover:before {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.gallery-image__link:hover:after {
  opacity: 1;
  position: absolute;
  content: "";
  left: 30px;
  top: 30px;
  bottom: 30px;
  right: 30px;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.gallery-page__link {
  background-color: #5EACDD;
  display: block;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s linear;
}
.gallery-page__link:hover {
  background-color: #FFB100;
}

.gallery-page__link-text {
  padding: 30px;
}

.gallery-item__title-logo span {
  font-size: 30px;
  color: #fff;
}

.gallery-item__sec-title {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  border-top: 1px solid #fff;
  padding-top: 10px;
  margin-bottom: 0;
  line-height: 1.3;
}
@media (min-width: 768px) {
  .gallery-item__sec-title {
    font-size: 32px;
  }
}

.pl_item_img img {
  display: block;
  width: 100%;
}

.pl_item_inner {
  position: relative;
}

.pli_text_holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  background-color: rgba(249, 247, 247, 0.95);
  transition: opacity 0.6s;
}

.pli_category_holder {
  margin-bottom: 10px;
}

.pli_category_holder a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #d0a046;
  letter-spacing: 0.1em;
}

.pli_category_holder a:after {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -10px;
  bottom: -4px;
  width: 20px;
  height: 1px;
  background-color: #000;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.pl_item_link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.pl_item_inner:hover .pli_category_holder a:after {
  width: 100%;
  left: 0;
  margin-left: 0;
  background-color: #d0a046;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.pl_item_inner:hover .pli_text_holder {
  opacity: 1;
  transition: opacity 0.6s;
}

/************************************************/
/*************SERVICE SND POST SINGLE************/
/************************************************/
.service-single__content {
  margin-bottom: 40px;
  background-color: #FAFAFA;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
}
@media (max-width: 767px) {
  .service-single__content {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.service-text-inner {
  padding: 0 40px 40px 40px;
}

.service-preview-img {
  margin-bottom: 40px;
}

.service-inner-title,
.post-inner-title {
  text-align: center;
  margin-bottom: 25px;
}

.service-text-inner .service-text,
.post-text-inner .service-text {
  color: #838487;
  line-height: 1.6;
}

.service-text-inner .service-text p,
.post-text-inner .service-text p {
  margin-bottom: 10px;
}

/************************************************/
/**********SERVICE SINGLE CARUSEL IMAGES*********/
/************************************************/
.service-preview-img img {
  display: block;
  width: 100%;
}

.carousel-service-nav {
  overflow: hidden;
}

.carousel-service-nav .slick-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  padding: 0;
  color: #fff;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 10;
  background-color: #d0a046;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.carousel-service-nav .slick-prev {
  left: -50px;
}

.carousel-service-nav .slick-next {
  right: -50px;
}

.service-preview-img:hover .carousel-service-nav .slick-prev {
  left: 0;
}

.service-preview-img:hover .carousel-service-nav .slick-next {
  right: 0;
}

/************************************************/
/****************CURRENT GUIDE BOX***************/
/************************************************/
.curent-guides-wrapp {
  margin-bottom: 50px;
}

.guid-title {
  margin-bottom: 35px;
}

.guide-item {
  display: flex;
  align-items: center;
}

.guide-photo {
  width: 82px;
  overflow: hidden;
}

.guide-photo a,
.guide-photo img {
  display: block;
}

.guide-photo img {
  width: 82px;
  height: 120px;
  object-fit: cover;
}

.guide-info {
  padding-left: 15px;
}

.guide-name {
  line-height: 1;
  margin-bottom: 5px;
}

.guide-name a {
  text-transform: uppercase;
  font-size: 14px;
}

.guide-position {
  font-size: 12px;
  text-transform: uppercase;
  color: #d0a046;
}

.hero-video__bg-image {
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .hero-video__bg-image {
    height: 700px;
  }
}

.hero-video__btn {
  color: #fff;
  background-color: #FFB100;
  width: 70px;
  height: 70px;
  line-height: 50px;
  border-radius: 70px;
  text-align: center;
  font-size: 40px;
  display: block;
  position: relative;
  border: 4px solid #fff;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.hero-video__btn:hover, .hero-video__btn:focus {
  color: #FFB100;
  background-color: #fff;
  border-color: #FFB100;
}

.hero-video__caption-title {
  color: #ffffff;
  font-size: 40px;
  line-height: 1;
  font-weight: 900;
  border-top: 1px solid #FFB100;
  padding-top: 20px;
  min-width: 50%;
  display: inline-block;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) {
  .hero-video__caption-title {
    font-size: 130px;
  }
}

.hero-video__caption-sub-title {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  text-shadow: 2px 3px 5px #0000007d;
}
@media (min-width: 768px) {
  .hero-video__caption-sub-title {
    font-size: 30px;
  }
}

.hero-video__btn-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 40px;
  display: block;
  position: absolute;
  top: 10px;
  left: 15px;
}

.hero-video__container .cta__title-logo span {
  color: #FFB100;
}

.hero-video__caption {
  padding-top: 100px;
  width: 100%;
}