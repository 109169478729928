/************************************************/
/******************MAIN HEADER*******************/
/************************************************/
.main_header_wrapp {
  position: relative;
  height: 125px;
  @include breakpoint($max992) {
    height: 90px;
  }
}

.logohead,
.main_header_right {
  position: relative;
  z-index: 10;
}

/*Main Head Logo*/
.logohead {
  float: left;
  height: 100%;
}

.logohead a {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;

  @include breakpoint($max992) {
    width: 160px;
  }
}

.logohead:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
}

/*Main Head Right*/
.main_header_right {
  float: right;
  height: 100%;
}

.main_header_right_inner {
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.main_header_right_inner a {
  text-decoration: none;
  color: #000;
  line-height: 1;
}

/*Lang btn list */
.lang-btn__list {
  margin-right: 20px;
  a {
    //display: block;
    font-family: $font_primary;
    font-size: 14px;
    line-height: 1;
    padding: 0 5px 0 5px;
    text-transform: uppercase;
    color: #000;
    text-decoration: none;
    letter-spacing: 0.2em;
    &:hover {
      color: $yellow_c;
    }
    &.active {
      opacity: .5;
      &:hover {
        color: #000;
        cursor: initial;
      }
    }
  }
}

/*Search*/
.search-btn a {
  font-size: 22px;
}

/*Sidebar Btn*/
.sidebar-btn {
  margin-left: 25px;
}

/*Shoping Cart DropDown*/
.shopping-cart-holder {
  margin: 0 0 0 15px;
}

.shopping-cart-holder,
.shopping-cart-inner {
  height: 100%;
}

.shopping-cart-inner {
  position: relative;
}

.header-cart {
  position: relative;
  font-size: 25px;
  color: #000;
}

.header-cart:before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
}

.header-cart .cart-number {
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: center;
  font-size: 11px;
  line-height: 1.3;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $yellow_c;
}

.shopping-cart-holder .widget_shopping_cart_content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 90px;
  background-color: #fff;
  width: 280px;
  transform: translateY(-10px);
  transition: all 0.2s linear;
}

.shopping-cart-inner:hover .widget_shopping_cart_content {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s linear;
}

/***********Top Nav**************/
.top_main_nav,
.top_nav_links,
.top_nav_links_wrapp,
ul.navbar-nav {
  height: 100%;
}

.top_main_nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.top_nav_links {
  padding: 0;
}

.top_nav_links ul.navbar-nav {
  margin: auto;
}

.top_nav_links ul.navbar-nav li {
  position: relative;
}

.top_nav_links ul.navbar-nav > li {
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top_nav_links .navbar-nav a {
  display: block;
  font-family: $font_primary;
  font-size: 14px;
  line-height: 1;
  padding: 0 20px 0 20px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  letter-spacing: 0.2em;
}

.top_nav_links .navbar-nav a span {
  position: relative;
  display: inline-block;
}

.top_nav_links .navbar-nav a span:after {
  position: absolute;
  content: '';
  left: -6px;
  right: -4px;
  top: -3px;
  bottom: -3px;
  box-shadow: 0 0 0 1px $yellow_c;
  transform: scale(0, 0);
  transition: all 0.2s linear;
}

.top_nav_links .navbar-nav .current-menu-item a span:after,
.top_nav_links .navbar-nav li:hover > a span:after {
  transform: scale(1, 1);
}

.top_nav_links .current-menu-item > a,
.top_nav_links li:hover > a {
  color: $yellow_c;
}

.top_nav_links .navbar-nav .sub-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 220px;
  padding: 17px 0;
  background-color: #fff;
  box-sizing: border-box;
  left: 0;
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.05);
  top: 90px;
  transform: translateY(-10px);
  transition: all 0.2s linear;
  z-index: 9;
}

.main_sticky_header .top_nav_links .navbar-nav .sub-menu {
  top: 65px;
}

.top_nav_links .navbar-nav .sub-menu .sub-menu {
  left: 100%;
  top: 0;
  box-shadow: 10px 12px 50px rgba(0, 0, 0, 0.05);
}

.top_nav_links .navbar-nav .sub-menu li {
  float: none;
  padding: 0 25px;
}

.top_nav_links .navbar-nav .sub-menu li a {
  display: block;
  padding: 8px 0;
  border-bottom: 1px solid #b3b3b3;
  opacity: .95;
}

.top_nav_links .navbar-nav .sub-menu li:last-child a {
  border-bottom: 0px solid #b3b3b3;
}

.top_nav_links .navbar-nav .sub-menu a span:after {
  display: none;
}

.top_nav_links .menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s linear;
}