
.sec-header{
margin-bottom: 24px;
}


.title-logo {
  color: $yellow_c;
  line-height: 1;

  span{
    font-size: 30px;
    position: relative;
    bottom: -1px;
  }
}
.sec-title__logo{
  display: inline-block;
  border-top: 1px solid $yellow_c;
  padding: 0 10px;
  padding-top: 8px;
}
.sec-title__1{
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  @include breakpoint($min992) {
    font-size: 24px;
  }
  @include breakpoint($min1200) {
    font-size: 32px;
  }
}
.sec-title__2{
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 400;
}
.sec-title__3{
  margin-bottom: 0;
  font-size: 20px;
}
.sec-title__4{
  margin-bottom: 0;
  font-size: 18px;
}
.sec-title__no-logo{
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  @include breakpoint($min992) {
    font-size: 24px;
  }
  @include breakpoint($min1200) {
    font-size: 30px;
  }
}
.sec-image{


  img{
    border: 5px solid #fff;
    display: inline-block;
    box-shadow: 0 7px 30px rgba(0, 0, 0, 0.1);
  }
}
.full-width-sec{
  margin-left: -15px;
  margin-right: -15px;

  @include breakpoint($min768) {

  }
  @include breakpoint($min992) {
    margin-left: -50px;
    margin-right: -50px;
  }
  @include breakpoint($min1200) {

  }
}
/************************************************/
/****************SERVICES SECTION****************/
/************************************************/
.services-sec{
  padding-top: 100px;
  padding-bottom: 60px;
  @include breakpoint($max992) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}
.services-sec__body {
  background-image: url("../img/service-bg-2-40.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 80px;
  margin-top: -80px;
  @include breakpoint($min576){
    padding-top: 110px;
    margin-top: -110px;
  }
  @include breakpoint($min768){
    padding-top: 160px;
    margin-top: -160px;
  }
  @include breakpoint($min992){
    padding-top: 200px;
    margin-top: -200px;
  }
  @include breakpoint($min1200){
    padding-top: 260px;
    margin-top: -260px;
  }
  @include breakpoint($min1600){
    padding-top: 300px;
    margin-top: -300px;
  }

}
.services-sec__inner{
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  @include breakpoint($min768) {
    padding-top: 100px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.services-sec__card {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5px;
}
.services-sec__card-title {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  a{
    color: #000;
    display: block;
    &:hover{
      color: $yellow_c;
      text-decoration: underline;
    }
  }
}
.services-sec__card-text {
  color: #838487;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}
.services-sec__action{

}
/************************************************/
/****************NEWS SECTION****************/
/************************************************/
.news-sec{
padding-top: 50px;
padding-bottom: 50px;
}










