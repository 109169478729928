.hero-video__bg-image {
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  @include breakpoint($min768) {
    height: 700px;
  }
}

.hero-video__btn {
  color: #fff;
  background-color: $yellow_c;
  width: 70px;
  height: 70px;
  line-height: 50px;
  border-radius: 70px;
  text-align: center;
  font-size: 40px;
  display: block;
  position: relative;

  border: 4px solid #fff;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);

  &:hover,
  &:focus {
    color: $yellow_c;
    background-color: #fff;
    border-color: $yellow_c;
  }
}

.hero-video__caption-title {
  color: #ffffff;
  font-size: 40px;
  line-height: 1;
  font-weight: 900;
  border-top: 1px solid #FFB100;
  padding-top: 20px;
  min-width: 50%;
  display: inline-block;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  @include breakpoint($min768) {
    font-size: 130px;
  }
}

.hero-video__caption-sub-title {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  text-shadow: 2px 3px 5px #0000007d;
  @include breakpoint($min768) {
    font-size: 30px;
  }
}

.hero-video__btn-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 40px;
  display: block;
  position: absolute;
  top: 10px;
  left: 15px;
}





.hero-video__container {
  .cta__title-logo span {
    color: $yellow_c;
  }
}

.hero-video__caption {
  padding-top: 100px;
  width: 100%;
}


