/************************************************/
/*************SERVICE SND POST SINGLE************/
/************************************************/


.service-single__content {
  margin-bottom: 40px;
  background-color: #FAFAFA;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .12);
  @include breakpoint($max768) {
   margin-left: -30px;
   margin-right: -30px;
  }
}

.service-text-inner {
  padding: 0 40px 40px 40px;

}

.service-preview-img {
  margin-bottom: 40px;
}

.service-inner-title,
.post-inner-title {
  text-align: center;
  margin-bottom: 25px;
}

.service-text-inner .service-text,
.post-text-inner .service-text {
  color: #838487;

  line-height: 1.6;
}

.service-text-inner .service-text p,
.post-text-inner .service-text p {
  margin-bottom: 10px;
}

/************************************************/
/**********SERVICE SINGLE CARUSEL IMAGES*********/
/************************************************/
.service-preview-img img {
  display: block;
  width: 100%;
}

.carousel-service-nav {
  overflow: hidden;
}

.carousel-service-nav .slick-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  padding: 0;
  color: #fff;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 10;
  background-color: #d0a046;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.carousel-service-nav .slick-prev {
  left: -50px;
}

.carousel-service-nav .slick-next {
  right: -50px;
}

.service-preview-img:hover .carousel-service-nav .slick-prev {
  left: 0;
}

.service-preview-img:hover .carousel-service-nav .slick-next {
  right: 0;
}

/************************************************/
/****************CURRENT GUIDE BOX***************/
/************************************************/
.curent-guides-wrapp {
  margin-bottom: 50px;
}

.guid-title {
  margin-bottom: 35px;
}

.guide-item {
  display: flex;
  align-items: center;
}

.guide-photo {
  width: 82px;
  overflow: hidden;
}

.guide-photo a,
.guide-photo img {
  display: block;
}

.guide-photo img {
  width: 82px;
  height: 120px;
  object-fit: cover;
}

.guide-info {
  padding-left: 15px;
}

.guide-name {
  line-height: 1;
  margin-bottom: 5px;
}

.guide-name a {
  text-transform: uppercase;
  font-size: 14px;
}

.guide-position {
  font-size: 12px;
  text-transform: uppercase;
  color: #d0a046;
}