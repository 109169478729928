/************************************************/
/******************MOBILE NAV********************/
/************************************************/
.mobile_nav {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 990;
}
.mobile_nav .back_cover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(249, 247, 248, 0.4);
}
.mobile_nav_inner {
  position: absolute;
  left: -300px;
  top: 0;
  width: 300px;
  height: 100%;
  min-height: 100%;
  text-align: left;
  padding: 40px 0;
  overflow: hidden;
  visibility: hidden;
  z-index: 9999;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  box-shadow: 0 3px 1px 0 rgba(187, 187, 187, 0.5);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  overflow-y: scroll;
}
.mobile_nav.show .mobile_nav_inner {
  left: 0;
  visibility: visible;
}
.mobile_nav_inner .close_win_btn {
  position: absolute;
  right: 15px;
  top: 15px;
}
.mobile_nav li {
  position: relative;
  border-bottom: 1px solid #eaeaea;
}
.mobile_nav .sub-menu {
  display: none;
  padding: 0 15px;
}
.mobile_nav li:last-child {
  border-bottom: none;
}
.mobile_nav li a {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 10px 30px;
}
.mobile_nav .sub_menu_btn {
  position: absolute;
  top: 4px;
  right: 20px;
  width: 30px;
  height: 30px;
  padding-top: 9px;
  box-sizing: border-box;
}