/************************************************/
/*****************PAGE HEADER********************/
/************************************************/
.page_header {
  position: relative;
  min-height: 305px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fff;
  margin-bottom: 60px;
}

.page_header {
  .sec-title__1 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    @include breakpoint($min992) {
      font-size: 32px;
    }
    @include breakpoint($min1200) {
      font-size: 50px;
    }
  }
  .title-logo{
    span{
      font-size: 30px;
      @include breakpoint($min768) {
        font-size: 50px;
      }
    }
  }
}
.page_header.no-image{
  .sec-title__1 {
    color: $text_c;
  }
}
.cover_color {
  position: relative;
}

.cover_color:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .2;
  z-index: -1;
}

.page_header_wrapper {
  position: relative;
  z-index: 2;
}

.parallaximg {
  display: none;
}