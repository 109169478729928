.call-to-action{
  background-image: url("../img/call-to-action-2.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @include breakpoint($max768) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.cta__title{
  color: #fff;
  font-size: 60px;
  font-weight: 300;
  border-top: 1px solid #fff;
  padding-top: 20px;
  @include breakpoint($max768){
    font-size: 40px;
  }
}
.cta__title-logo{
  span{
    font-size: 80px;
    color: #fff;
  }

}