/************************************************/
/***************DEFAULT CLASSES******************/
/************************************************/
.bodypadding {

  padding: 0 15px;
  @include breakpoint($min768) {

  }
  @include breakpoint($min992) {
    padding: 0 50px ;
  }
  @include breakpoint($min1200) {

  }
}
.padding100_0 {
  padding: 100px 0;
}
.padding150_0 {
  padding: 150px 0;
}
.padding75_0 {
  padding: 75px 0;
}
.padding_top_250 {
  padding-top: 250px;
}
.padding_top_150 {
  padding-top: 150px;
}
.padding_top_130 {
  padding-top: 130px;
}
.padding_top_100 {
  padding-top: 100px;
}
.padding_btn_80 {
  padding-bottom: 80px;
}
.padding_btn_75 {
  padding-bottom: 75px;
}
.padding_btn_150 {
  padding-bottom: 150px;
}
.padding_btn_30 {
  padding-bottom: 30px;
}
.margin_off {
  margine: 0;
}
.lighbg {
  background-color: #f9f7f7;
}
.lightext {
  color: #838487;
}
.margin_btn_50 {
  margin-bottom: 50px;
}
.slick-slide {
  outline: none!important;
}
/*****Bars Lines******/
//.burslines,
//.burslines .line,
//.burslines_menu,
//.burslines_menu .line {
//  display: block;
//  outline: none;
//}
//.burslines {
//  max-width: 100px;
//  overflow: hidden;
//}
//.burslines .line,
//.burslines_menu .line {
//  height: 1px;
//  width: 40px;
//  margin-bottom: 5px;
//  background-color: #000;
//  transition: all 0.3s linear;
//}
//.burslines_menu .line {
//  width: 30px;
//}
//.burslines .line:last-child {
//  margin-bottom: 0;
//}
//.burslines:hover .line:first-child {
//  transform: translateX(15px);
//}
//.burslines:hover .line:last-child {
//  transform: translateX(-15px);
//}
/*Nuv Button Toggle*/
.navbar-toggler.show .burslines_menu .line:first-child,
.close_sub_menu .line:first-child {
  transform: translateY(5px) rotate(45deg);
}
.navbar-toggler.show .burslines_menu .line:nth-child(2),
.close_sub_menu .line:nth-child(2) {
  opacity: 0;
}
.navbar-toggler.show .burslines_menu .line:last-child,
.close_sub_menu .line:last-child {
  margin-bottom: 0;
  transform: translateY(-7px) rotate(-45deg);
}
.navbar-toggler {
  padding: 0;
}
.navbar-toggler {
  outline: none!important;
}
/*fgbobles*/
.fgbobleslink {
  position: relative;
  text-align: center;
  background-color: transparent;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.fgbobles {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -17px;
  margin-left: -21px;
}
.fgbobles .boble {
  opacity: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 1px;
  border-radius: 50%;
  background-color: $yellow_c;
}
.fgbobles .boble:first-child {
  transform: translateX(-40px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.fgbobles .boble:last-child {
  transform: translateX(40px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.fgbobles .boble:nth-child(2) {
  transform: translateY(-40px);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.fgbobleslink:hover .fgbobles .boble {
  opacity: 1;
  transform: translateX(0);
  transform: translateY(0);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.fgbobleslink:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}