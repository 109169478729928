/************************************************/
/*****************FLOAT SITEBAR******************/
/************************************************/
.float_sidebar_window {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
}

.float_sidebar_window .back_cover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(249, 247, 248, 0.4);
}

.float_sidebar_window_inner {
  position: fixed;
  right: -475px;
  top: 0;
  width: 475px;
  margin-right: -20px;
  height: 100%;
  min-height: 100%;
  text-align: left;
  padding: 5.5% 80px 38px 79px;
  overflow: hidden;
  visibility: hidden;
  z-index: 9999;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  box-shadow: 0 3px 1px 0 rgba(187, 187, 187, 0.5);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  overflow-y: scroll;
}

.float_sidebar_window.show .float_sidebar_window_inner {
  right: 0;
  visibility: visible;
}

.float_sidebar_container {
  font-size: 18px;
  color: #838487;
  text-align: center;
}

.float_sidebar_window .close_win_btn {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.nicescroll-cursors {
  background-color: #fff !important;
}

/************************************************/
/*************FLOAT SITEBAR WIDGETS**************/
/************************************************/
.float_sidebar_container .widget_sidearea {
  margin-bottom: 15px;
}

.float_sidebar_container .widget_text p {
  font-weight: 300;
  line-height: 1.6;
}

.intsgramname {
  color: $yellow_c;
  font-style: italic;
}