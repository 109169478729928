/************************************************/
/*********************FOOTER*********************/
/************************************************/
.main-footer {
  //padding-top: 250px;
  //  //margin-top: -250px;
  padding-top: 80px;
  background-color: #f9f7f7;
  //padding: 100px 0 0 0;
  background-image: url("../img/footer-bg-14.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include breakpoint($max768) {
    padding-top: 40px;
  }

}
.page_home{
  .main-footer {
    padding-top: 250px;
    margin-top: -250px;
    @include breakpoint($max768) {
      padding-top: 300px;
      margin-top: -300px;
    }
  }
}
.footer-bottom {
  padding: 45px 0;
  margin-top: 30px;
  border-top: 1px solid #646262;
}
.footer-bottom .copyright {
  float: left;
  font-size: 14px;
  color: #333;
  //font-weight: 300;
}
.footer-bottom .socilalist_footer {
  float: right;
}
.footer-bottom .socilalist_footer a {
  font-weight: 300;
  color: #838487;
}

.footer-logo{
  img{
    max-width: 200px;
  }
}
.footer-logo__text{
  color: #333;
  b{
    font-weight: 700;
  }
}

.signature {
  color: #333;
  a {
    color: #333;
    background-image: url(../img/apaone-logo-rgay.png);
    background-repeat: no-repeat;
    padding-left: 20px;
    background-position: 0;
    text-decoration: none;
  }
}




.contact-list li {
  position: relative;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 8px 0 8px 35px;
  color: #333;
  a{
    color: #333;
    &:hover{
      color: $red_c;
      text-decoration: underline;
    }
  }
}
.contact-list li span.f-icon {
  position: absolute;
  left: 0;
  font-size: 22px;
  color: $red_c;
}
.footer-col {
  .widget_text {
    font-size: 14px;
    color: #838487;
  }
  .widget-recent-post .post-name a:hover,
  .widget-recent-post .post-data{
    color: $red_c;
  }
}
.widget-footer__title{
  color: #333;
}
.footer-soc-link{
    display: inline-block;
    padding: 10px;
    font-size: 18px;
}
