/************************************************/
/*****************BUTTONS CLASS******************/
/************************************************/
.btn_frl {
  position: relative;
  display: inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding: 12px 25px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
}

.btn_frl_big {
  padding: 18px 45px;
}

.btn_frl.btn_trnsp {
  background-color: transparent;
  border: 1px solid #000;
}

.btn_frl.btn_gold {
  background-color: $yellow_c;
  color: #fff;

}

.btn_frl.btn-white {
  background-color: #fff;
  color: #333;
  &:after{
    box-shadow: 0 0 0 1px #fff;
  }
}

.btn_frl.btn-black {
  background-color: #222;
  color: #fff;
  &:after{
    box-shadow: 0 0 0 1px #222;
  }
}

.btn_frl:after {
  opacity: 0;
  position: absolute;
  content: '';
  left: -4px;
  right: -4px;
  bottom: -4px;
  top: -4px;
  box-shadow: 0 0 0 1px $yellow_c;
  transform: scale(0.5, 0.5);
  transition: transform .4s, opacity .4s;
}

.btn_frl i {
  opacity: 0;
  display: inline-block;
  position: relative;
  top: -1px;
  max-width: 0;
  font-size: 13px;
  width: 1.8em;
  vertical-align: middle;
  text-align: right;
  text-indent: 0;
  line-height: 1;
  transition: opacity 0.3s, max-width 0.3s;
}

.btn_frl.btn_gold i {
  color: #fff;
}

.btn_frl:hover,
.btn_frl:focus {
  border: none;
  outline: none;
}

.btn_frl:hover:after {
  transform: scale(1, 1);
  opacity: 1;
  transition: transform .2s, opacity .2s;
}

.btn_frl.btn_trnsp:hover {
  background-color: $yellow_c;
  color: #fff;
  border: 1px solid $yellow_c;
}

.btn_frl:hover i {
  opacity: 1;
  max-width: 3em;
  will-change: opacity, max-width;
  transition: opacity 0.3s, max-width 0.3s;
}

.btn_frl_sml {
  position: relative;
  display: inline-block;
  padding: 1px 5px;
  margin-bottom: 4px;
  background-color: transparent;
  outline: none;
  font-size: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  color: $yellow_c;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border: 1px solid $yellow_c;
}

.btn_frl_sml {
  color: #000;
  border: 1px solid #000;
}


.btn_frl_sml:after {
  position: absolute;
  content: '';
  left: -4px;
  top: -4px;
  bottom: -4px;
  right: -4px;
  box-shadow: 0 0 0 1px $yellow_c;
  transform: scale(0.4, 0.4);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}

.btn_frl_sml:after {
  box-shadow: 0 0 0 1px #000;
}

.btn_frl_sml:hover {
  background-color: $yellow_c;
  color: #fff;
  outline: none;
  border-color: $yellow_c;
  transition: background-color 0.6s;
}

.btn_frl_sml:hover:after {
  transform: scale(1, 1);
  opacity: 1;
  box-shadow: 0 0 0 1px $yellow_c;
  transition: transform .3s, opacity .3s;
}

/*Buton Click Effect*/
.btn_frl_flt {
  position: relative;
  font-size: 12px;
  line-height: 1;
  outline: none;
  padding: 0 1px;
  background-color: transparent;
  border: none;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: color .3s;
}

.btn_frl_flt.active {
  color: $yellow_c;
}

.btn_frl_flt:after {
  position: absolute;
  content: '';
  left: -3px;
  top: -3px;
  bottom: -3px;
  right: -3px;
  box-shadow: 0 0 0 1px $yellow_c;
  opacity: 0;
  transform: scale(0.4, 0.4);
}

.btn_frl_flt.active:after {
  opacity: 1;
  transform: scale(1, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

.btn_frl_flt:focus,
.btn_frl_flt:hover {
  outline: none;
  color: $yellow_c;
  transition: color .3s;
}