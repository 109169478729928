
/************************************************/
/**************SLICK SLIDER STYLES***************/
/************************************************/
.farrellslick_nav_wrapp {
  text-align: center;
  padding-top: 30px;
}
.farrellslick_nav_inner {
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 0 35px;
}
.farrellslick_nav_inner .slick-arrow {
  position: absolute;
  top: 4px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
}
.farrellslick_nav_inner .slick-prev {
  left: 0;
}
.farrellslick_nav_inner .slick-next {
  right: 0;
}
.farrellslick_nav_inner .slick-dots {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.farrellslick_nav_inner .slick-dots li {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.farrellslick_nav_inner .slick-dots li {
  line-height: 0;
}
.farrellslick_nav_inner .slick-dots li button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  color: transparent!important;
  font-size: 12px;
  width: 30px;
  height: 1px;
  background-color: #efeeed;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.farrellslick_nav_inner .slick-dots li.slick-active button {
  background-color: #000;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}