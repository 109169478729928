/************************************************/
/******************BREADCRUMBS*******************/
/************************************************/
.small-breadcrumbs{
  .breadcrumbs-sec {
    margin-bottom: 20px;
  }
}
.breadcrumbs-sec {
  margin-bottom: 60px;
  padding: 10px 0;
  border-top: 1px solid #efeeed;
  border-bottom: 1px solid #efeeed;
  @include breakpoint($max768) {
    margin-bottom: 20px;
  }
}
.page_list li {
  float: left;
}
.page_list li,
.page_list a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.page_list li .separatelist {
  display: inline-block;
  padding: 0 13px;
}