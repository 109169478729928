/************************************************/
/*****************STICKY HEADER******************/
/************************************************/
.main_sticky_header {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  z-index: 15;
  box-sizing: border-box;
  padding: 0 50px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.6s ease;
  -moz-transition: -moz-transform 0.6s ease;
  transition: transform .6s ease;
  .logohead{
    img{
      width: 160px;
    }
  }
  @include breakpoint($max992) {
    padding: 0 15px;
  }
}
.main_sticky_header.header_appear {
  transform: translateY(0);
}