/**************************************************/
/*****************TYPOGRAPHY***********************/
/**************************************************/
h1, h2, h3, h4, h5, h6 {
  font-family: $font_primary;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 15px;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 30px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

a {
  color: #000;
  transition: color .2s;
}

a:hover,
a:focus {
  color: $yellow_c;
  transition: color .2s;
  text-decoration: none;
  outline: none;
}
