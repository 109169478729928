/************************************************/
/***************POST NAVIGATION******************/
/************************************************/
.post-navigation {
  position: relative;
  text-align: center;
  padding: 23px 0;
  border-top: 1px solid #efeeed;
  border-bottom: 1px solid #efeeed;
  //margin-bottom: 60px;
}
.post-navigation .prev,
.post-navigation .next {
  position: absolute;
  top: 50%;
  margin-top: -13px;
}
.post-navigation .prev {
  left: 0;
}
.post-navigation .next {
  right: 0;
}
.post-navigation a {
  color: #838487;

  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1em;
  opacity: 0.6;
  transition: color, opacity, 0.6s;
}
.back-to-parent a {
  font-size: 24px;
}
.post-navigation a:hover {
  opacity: 1;
  color: $yellow_c;
  transition: color, opacity, 0.6s;
}