.gallery-sec{
  padding-top: 50px;
  padding-bottom: 50px;
}

.gallery-item{
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
}
.gallery-image__link {
  display: block;
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
  &:before {
    opacity: 0;
    position: absolute;
    content: "\e909";
    font-family: 'icomoon' !important;
    font-size: 22px;
    left: 50%;
    top: 50%;
    margin-left: -11px;
    margin-top: -15px;
    color: #d0a046;
    transform: translateY(-50px);
    transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 2;
  }
  &:after {
    opacity: 0;
    position: absolute;
    content: "";
    left: -20px;
    top: -20px;
    bottom: -20px;
    right: -20px;
    border: 1px solid #f9f7f7;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 1;
  }
  &:hover{
    &:before {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    }
    &:after {
      opacity: 1;
      position: absolute;
      content: "";
      left: 30px;
      top: 30px;
      bottom: 30px;
      right: 30px;
      transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
}
.gallery-page__link{
  background-color: $blue_c;
  display: block;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s linear;
  &:hover{
    background-color: $yellow_c;
  }
}
.gallery-page__link-text{
  padding: 30px;
}
.gallery-item__title-logo{
  span{
    font-size: 30px;
    color: #fff;
  }
}
.gallery-item__sec-title{
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  border-top: 1px solid #fff;
  padding-top: 10px;
  margin-bottom: 0;
  line-height: 1.3;
  @include breakpoint($min768) {
    font-size: 32px;
  }
}







.shuffle_photos_item {
  //width: 20%;
}
.shuffle_photos_item.shuffle_photo_large {
  //width: 40%;
}
.pl_item_img img {
  display: block;
  width: 100%;
}
.pl_item_inner {
  position: relative;
}
.pli_text_holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  background-color: rgba(249, 247, 247, 0.95);
  transition: opacity 0.6s;
}
.pli_category_holder {
  margin-bottom: 10px;
}
.pli_category_holder a {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #d0a046;
  letter-spacing: 0.1em;
}
.pli_category_holder a:after {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -10px;
  bottom: -4px;
  width: 20px;
  height: 1px;
  background-color: #000;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.pl_item_link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.pl_item_inner:hover .pli_category_holder a:after {
  width: 100%;
  left: 0;
  margin-left: 0;
  background-color: #d0a046;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.pl_item_inner:hover .pli_text_holder {
  opacity: 1;
  transition: opacity 0.6s;
}