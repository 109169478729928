

/************************************************/
/*************SERVICES CARD SLIDER***************/
/************************************************/
.services_cards_slides .slick-slide {
  padding-left: 30px;
}
.services_cards_slides .slick-list {
  margin-left: -30px;
}
.services_cards_slides .item_inner {
  outline: none!important;
}
.services_cards_slides .item {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.services_cards_slides .item:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgba(13, 37, 66, 0) 57%, #000000 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(30, 87, 153, 0) 0%, rgba(13, 37, 66, 0) 57%, #000000 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(13, 37, 66, 0) 57%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001e5799', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}
.services_cards_slides .item > img {
  width: 100%;
}
.item_link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  outline: none;
}
.services_cards_slides .carddesc {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  z-index: 2;
}
.services_cards_slides .namecard {
  font-weight: 700;
  color: #fff;
  font-size: 40px;
  margin-bottom: 5px;
}
.services_cards_slides .shortcarddesc {
  font-size: 14px;
  max-width: 300px;
  margin: auto;
}
/*Demo Background*/
.sishingbg {
  background-image: url(../images/cards1.jpg);
}
.kayakingbg {
  background-image: url(../images/cards2.jpg);
}
.shopgbg {
  background-image: url(../images/cards3.jpg);
}
.navslider {
  margin-top: 35px;
}
.navslider_inner {
  position: relative;
  padding: 0 30px;
  max-width: 340px;
  margin: auto;
  height: 22px;
}
.navslider_inner .slick-list {
  top: 7px;
}
.navslider_inner .item {
  display: inline-block;
  background-color: transparent;
  border: none;
  padding: 2px 0;
  font-size: 10px;
  line-height: 1.3;
  color: transparent;
  outline: none;
  cursor: pointer;
  width: 75px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.navslider_inner .slick-current {
  border-bottom: 1px solid #000;
}
.navslider_inner .slick-arrow {
  position: absolute;
  top: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.navslider_inner .slick-prev {
  left: 0;
}
.navslider_inner .slick-next {
  right: 0;
}