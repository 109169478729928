// vendor sass
@import 'sass-vendor/breakpoint';
// Variables
@import 'variables';
@import 'global';
@import 'effects';
@import 'typography';
@import 'default-classes';
/***
====================================================================
	Elements
====================================================================
 ***/
@import 'elements/button';
@import 'elements/breadcrumb';
@import 'elements/wysiwyg-content';
@import 'elements/form';
@import 'elements/sec';
@import 'elements/prev-post-card';
@import 'elements/call-to-action';
@import 'elements/breadcrumbs';
@import 'elements/post-navigation';
@import 'elements/pagination';
/***
====================================================================
	Layouts
====================================================================
 ***/
@import 'layout/page';
@import 'layout/mobile_nav';
@import 'layout/search_popup';
@import 'layout/float_sidebar';
@import 'layout/main_header';

@import 'layout/sticky_header';
@import 'layout/footer';
@import 'layout/slick_slider_styles';
@import 'layout/services_card_slide';
@import 'layout/widget';

@import 'layout/page_header';
@import 'layout/gallery';
@import 'layout/service-signle';
@import 'layout/home-slider';
//@import 'layout/home-about';
//@import 'layout/blog-card';
//@import 'layout/sidebar';
//@import 'layout/contact';

