/* Animations Linear*/
.scrollanimation {
  opacity: 0;
}
.animated {
  opacity: 1;
}
/*.fadeInUp{
	animation-duration:.6s;
	animation-timing-function: linear;
}
*/
/*cubic-bezier*/
/*scale*/
@keyframes scale {
  0%,
  100% {
    transform: scale(0);
    -moz-transform: scale(0);
  }
  7%,
  90% {
    transform: scale(0.4);
    -moz-transform: scale(0.4);
  }
  50% {
    transform: scale(1);
    -moz-transform: scale(1);
  }
}
/*popup Image ZoomIN effect*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}