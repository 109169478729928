.services-other-sec{
  background-color: #FAFAFA;
  padding-top: 100px;
  padding-bottom: 60px;
  @include breakpoint($max768) {
    margin-left: -15px;
    margin-right: -15px;
  }
  .item_post{
    margin-bottom: 30px;
  }
}
.page-main-image{
  margin-bottom: 50px;
  @include breakpoint($max768) {
    margin-bottom: 20px;
  }
  img{
    width: 100%;
  }
}

.news-list-sec{
  margin-bottom: 30px;
  @include breakpoint($max768) {
    margin-bottom: 20px;
  }
}

.error-page-sec{
  margin-bottom: 50px;
  @include breakpoint($max768) {
    margin-bottom: 20px;
  }
  a{
    color: $yellow_c;
    &:hover{
      text-decoration: underline;
    }
  }
}
.error-page__title{
  font-size: 40px;
  line-height: 1;
}
.error-page__content{
  p{
    font-size: 18px;
  }
}

.contact-sec{
  margin-bottom: 30px;
}
.map-sec{
margin-bottom: 50px;
}
.map-wrap{
  height: 400px;
}