html {
  min-height: 100%;

}

body {
  font-family: $font_primary;
  color: $text_c;
  position: relative;
  font-style: normal;
  background-color: #fff;


  img {
    max-width: 100%;
    display: block;
  }
}



.hidden {
  display: none;
}

a, a:hover, a:active, a:focus {
  text-decoration: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul ul, ol ol, ul ol, ol ul {
  padding-left: 25px;
}

input {
  outline: 0;
  box-shadow: none;
  border-radius: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font_secondary;
}

#map {
  height: 400px;
  width: 100%;
}


.req-symbol {
  font-weight: 500;
  color: $red_c;
  font-family: sans-serif;
}

/************************************************/
/***************SCROLL UP BUTTON****************/
/************************************************/
.sctollup.btn_frl {
  cursor: pointer;
  display: none;
  position: fixed;
  right: 50px;
  padding: 0;
  bottom: 50px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 2.9;
  text-align: center;
  background-color: $yellow_c;
  color: #fff;
  z-index: 900;
}
.sctollup span {
  opacity: 1;
  color: #fff;
  width: auto;
  top: 0;
  display: inline;
}




.required-mark{
  color: $red_c;
  font-weight: 700;
}








