/************************************************/
/**************PREVIEW POST CARD*****************/
/************************************************/
.prev-post__image-link {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 0;
    overflow: hidden;
}
.prev-post__image-link img,
.prev-post__image-link a {
    display: block;
}
.prev-post__image-link img {
    width: 100%;
    transform: scale(1.06);
    transition: transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
}
.prev-post__image-link:hover img {
    transform: scale(1.06) translateX(3%);
    transition: transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
}
.prev-pots__label {
    position: absolute;
    left: 0;
    top: 20px;
    z-index: 3;
    .prev-post__data {
        font-size: 14px;
        font-family: "Source Sans Pro", sans-serif;
        color: $yellow_c;
        padding: 4px 15px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        background-color: #fff;
    }
}

.prev-post__text,
.prev-post__link {
    text-align: center;
}
.prev-post__text {
    margin: 25px 0 0 0;
}
.prev-post__title {
    height: 46px;
    overflow: hidden;
    margin-bottom: 0px;
    a {
        &:hover {
            color: $yellow_c;
            text-decoration: underline;
        }
    }
}
.prev-post__short {
    color: #838487;
    margin-bottom: 18px;
    font-size: 14px;
    height: 40px;
    overflow: hidden;
}

.prev-post__card.small-card {
    background-color: #fafafa;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12);
    padding-bottom: 15px;
}
.prev-post__row-title{
    margin-bottom: 10px;
}
.prev-post__row-card   {

    @include breakpoint($min768) {
		display: flex;
	}
    .prev-post__image-link{
        flex: 1 0 25%;
        margin-right: 30px;
    }
    .prev-post__text{
        text-align: left;
    }
}
