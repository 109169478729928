.sidebar-widget{
  margin-bottom: 50px;
}
/************************************************/
/****************NAVIGATION WIDGET***************/
/************************************************/
.widget-nav-menu li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 15px;
}
.widget-nav-menu li:before {
  position: absolute;
  content: '•';
  left: 0;
  top: 0;
  color: $red_c;
}
.widget-nav-menu a {
  font-size: 14px;
  color: #333;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.widget-nav-menu a:hover {
  color: $red_c;
}



/************************************************/
/*******************RECENT POST******************/
/************************************************/
.widget-recent-post .post-data {
  font-size: 12px;
  font-weight: 600;
  color: $yellow_c;
  text-transform: uppercase;
}
.widget-recent-post .post-name a {
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #333;
  text-transform: uppercase;
}
.widget-recent-post .post-name a:hover {
  color: $yellow_c;
}



/************************************************/
/*******************SERVICES LIST******************/
/************************************************/
.widget-services-list{
  li {
    position: relative;
    margin-bottom: 7px;
    padding-left: 15px;
    &:before {
      position: absolute;
      content: '•';
      left: 0;
      top: 0;
      color: $yellow_c;
    }
  }
  a {
    font-size: 14px;
    color: #333;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    &:hover {
      color: $yellow_c;
    }

  }
}